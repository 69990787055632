import React from "react"

import Heading from "@kiwicom/orbit-components/lib/Heading"
import Stack from "@kiwicom/orbit-components/lib/Stack"
import Text from "@kiwicom/orbit-components/lib/Text"
import TextLink from "@kiwicom/orbit-components/lib/TextLink"

import Container from "components/container"
import { EmberCard, EmberCardSection } from "components/generic/ember-card"
import Hero from "components/hero"
import { DriveLayout } from "components/layout-custom"

const Page = () => (
  <DriveLayout title="Risk Assessments">
    <Hero title="Risk Assessments" />
    <Container size="medium">
      <EmberCard>
        <EmberCardSection>
          <Stack>
            <Text>
              We've put together a general risk assessment for the company which
              can be found below. If you come across any risks that you do not
              feel are adequately handled, please let us know and we will update
              the policy.
            </Text>
            <TextLink
              external
              href="https://docs.google.com/document/d/1RZCG4BZ8FEhaPri5fGr3vSB30K3edoSsXvPrUiz7_2o/edit?usp=sharing"
            >
              Ember Risk Assessment
            </TextLink>
            <Heading type="title2">COVID Risks</Heading>
            <Text>
              We've also prepared a COVID risk assessment for staff, which looks
              at risks associated with the pandemic and measures we are taking
              to mitigate these risks. In addition, Transport Scotland publish a{" "}
              <TextLink
                type="secondary"
                href="https://www.transport.gov.scot/coronavirus-covid-19/transport-transition-plan/advice-on-how-to-travel-safely/"
              >
                good overview
              </TextLink>{" "}
              of the latest guidance on how to travel safely.
            </Text>
            <TextLink
              external
              href="https://docs.google.com/document/d/1aK4YrsUBDT-_wh1AVG960tEjSAbQ3P1vwfATImlkYkE/edit?usp=sharing"
            >
              COVID Risk Assessment
            </TextLink>
          </Stack>
        </EmberCardSection>
      </EmberCard>
    </Container>
  </DriveLayout>
)

export default Page
